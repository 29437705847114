import React from 'react'
import './header.css'
import CustomScript from '../common/customeScript'
import { Link } from 'react-router-dom'
function Header({setIsIndividualModal,setIsRiderModal,setStoreModal,ishome}) {
  return (
    <header id="header" className="fixed-top d-flex align-items-center header-transparent">
      <div className="container d-flex align-items-center justify-content-between">

        <div className="logo">
          <h1><Link to="/"><span><img src='/images/Logo.png'/></span></Link></h1>
        </div>

        {
          !ishome?
          <nav id="navbar" className="navbar">
          <ul>
            
          <li className="dropdown">
          <a href="#"><span>Getting Started</span> <i className="bi bi-chevron-down"></i></a>
          <ul>
            <li className="dropdown">
              <a href="#"><span>SignUp</span> <i className="bi bi-chevron-right"></i></a>
              <ul>
                <li onClick={setIsRiderModal.bind(this, true)}><a href="#">Become a rider</a></li>
                <li className="dropdown">
                  <a href="#"><span>Client</span> <i className="bi bi-chevron-right"></i></a>
                  <ul>
                    <li onClick={setStoreModal.bind(this, true)}><a href="#">Business</a></li>
                    <li onClick={setIsIndividualModal.bind(this, true)}><a href="#">Individual</a></li>
                  </ul>
                </li>
              </ul>
            </li>
            <li><a className="nav-link scrollto active" href="https://sage-entremet-3990c3.netlify.app" target='_blank'>Login</a></li>
          </ul>
          </li>


            <li><a className="nav-link scrollto active" href="#hero">Home</a></li>
            <li><a className="nav-link scrollto" href="#about">About</a></li>
            <li><a className="nav-link scrollto" href="#features">Services</a></li>
            {/* <li><a className="nav-link scrollto" href="#team">Team</a></li> */}
            <li><a className="nav-link scrollto" href="#Help">Help / Support</a></li>
            <li><a className="nav-link scrollto" href="#contact">Contact Us</a></li>
          
          </ul>
          <i className="bi bi-list mobile-nav-toggle"></i>
        </nav>:

        <nav id="navbar" className="navbar">
        <ul>
          <li><Link to="/">Home</Link></li>
        </ul>
        <i className="bi bi-list mobile-nav-toggle"></i>
        </nav>
        }
        
      </div>
    </header>
  )
}

export default Header