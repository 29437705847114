import React, { useState } from 'react'
import RiderRegisterModal from '../components/modals/RiderRegisterModal'
import StoreRegisterModal from '../components/modals/StoreRegisterModal'
import IndividualModal from '../components/modals/IndividualModal'
import Header from '../components/header/Header'
import HeroTerms from '../components/terms/HeroTerms'
import { Link } from 'react-router-dom'

function Privacy() {
    const [isRiderModal,setIsRiderModal] = useState(false)
    const [isStoreModal,setStoreModal] = useState(false)
    const [isIndividualModal,setIsIndividualModal] = useState(false)
    const [storeformData, setStoreFormData] = useState({
        business_type:"pharmacy",
        fullname: "",
        businessName: "",
        businessAddress:"",
        email: "",
        phoneNumber: "",
        location:"",
        password: "",
        confirmPassword: "",
        license:"",
        description:"",
        isAgreement: false,
        
      });
      const [individualformData, SetIndividualFormData] = useState({
        fullname: "",
        email: "",
        phoneNumber:"",
        address: "",
        password: "",
        confirmPassword: "",
        isAgreement: false,
       
      });
      const [riderFormData, setRiderFormData] = useState({
        fullname: "",
        email: "",
        phoneNumber: "",
        password: "",
        confirmPassword:"",
        country:"",
        address:"",
        guarantor_name: "",
        guarantor_phone:"",
        guarantor_occupation:"",
        guarantor_relationship:"",
        isAgreement: false,
      });
  return (
    <div className='page'>
         <Header ishome/>

         {/* <HeroTerms 
            subtitle="Last modified: Dec, 2024"
            title="Privacy Policy"
            description="At AiRander, your privacy is important to us. This Privacy Policy outlines how we collect, use, and protect your information when you use our services. By using AiRander, you agree to the practices described in this policy."
        /> */}
         <HeroTerms
         subtitle="Last modified: Dec, 2024"
         title="Privacy Policy"
         description="At AiRander, your privacy is important to us. This Privacy Policy outlines how we collect, use, and protect your information when you use our services. By using AiRander, you agree to the practices described in this policy."
         />

<div className='terms-condition-main'>
        
        <div className='terms-condition-main-content'>
            <section className='terms-sec'  id="introduction">
            <h1>1. Information We Collect Information</h1>
            <p>
            We collect the following types of information to provide and improve our services:
            
            <ul>
                <li>
                    Personal Information
                    <ul>
                        <li>Name</li>
                        <li>Phone number</li>
                        <li>Email address</li>
                        <li>Delivery address and location details</li>
                    </ul>
                </li>
            </ul>
            <ul>
                <li>
                   Usage Data
                    <ul>
                        <li>Information about your interactions with our platform, including the services you use, timestamps, and preferences.</li>
                    </ul>
                </li>
            </ul>
            <ul>
                <li>
                Device Information
                    <ul>
                        <li>IP address, device type, operating system, and other technical data.</li>
                    </ul>
                </li>
            </ul>
            <ul>
                <li>
                Payment Information
                    <ul>
                        <li>If applicable, payment details required for processing transactions securely.</li>
                    </ul>
                </li>
            </ul>
            
            </p>

            <h1>2. How We Use Your Information</h1>
            <p>
                AiRander uses your information for the following purposes:
                <ul>
                    <li>To process and deliver your errand requests.</li>
                    <li>To communicate with you regarding updates, service notifications, and promotions.</li>
                    <li>To analyze usage trends and improve our platform.</li>
                    <li>To ensure security and prevent fraudulent activity.</li>
                </ul>
            </p>
            <h1>3. Sharing Your Information</h1>
            <p>
                We respect your privacy and only share your data in the following scenarios:
                <ul>
                    <li>With Air-Riders: Essential details (e.g., pick-up/drop-off address) needed to fulfill your requests.</li>
                    <li>Service Providers: Third-party partners assisting in payments, analytics, or other operational needs.</li>
                    <li>Legal Compliance: To comply with applicable laws, regulations, or legal processes.</li>
                    <li>Business Transactions: In case of mergers, acquisitions, or sale of assets, your information may be transferred.</li>
                </ul>
            </p>
            <h1>4. Data Retention</h1>
            <p>
                We retain your personal information for as long as necessary to provide services, comply with legal obligations, resolve disputes, and enforce agreements. Once data is no longer needed, we securely delete or anonymize it.
                <ul>
                    <li>With Air-Riders: Essential details (e.g., pick-up/drop-off address) needed to fulfill your requests.</li>
                    <li>Service Providers: Third-party partners assisting in payments, analytics, or other operational needs.</li>
                    <li>Legal Compliance: To comply with applicable laws, regulations, or legal processes.</li>
                    <li>Business Transactions: In case of mergers, acquisitions, or sale of assets, your information may be transferred.</li>
                </ul>
            </p>
            <h1>5. Your Rights</h1>
            <p>
                You have the right to:
                <ul>
                    <li>Access and review your personal information.</li>
                    <li>Request correction or deletion of inaccuracies in your data.</li>
                    <li>Withdraw consent for data processing where applicable.</li>
                    <li>Opt out of marketing communications.</li>
                </ul>
            </p>
            <h1>6. Data Security</h1>
            <p>
                We implement robust security measures to protect your data, including:
                <ul>
                    <li>Encryption for sensitive information.</li>
                    <li>Regular audits and updates to our security protocols.</li>
                </ul>
                While we strive to protect your information, no system is 100% secure. Please ensure the security of your account credentials.
            </p>
            <h1>7. Cookies and Tracking</h1>
            <p>
            AiRander uses cookies to enhance your experience. These cookies help us:
                <ul>
                    <li>Remember your preferences.</li>
                    <li>Analyze platform performance.</li>
                    <li>Deliver personalized services.</li>
                </ul>
                You can adjust your browser settings to control or block cookies; however, some features of AiRander may not function properly.
            </p>
            <h1>8. Updates to This Privacy Policy</h1>
            <p>
            We may update this Privacy Policy periodically. Changes will be posted on this page with an updated "Last Updated" date. We encourage you to review the policy regularly to stay informed about our practices.
                <ul>
                    <li>Remember your preferences.</li>
                    <li>Analyze platform performance.</li>
                    <li>Deliver personalized services.</li>
                </ul>
                You can adjust your browser settings to control or block cookies; however, some features of AiRander may not function properly.
            </p>
            <h1>9.  Contact Us</h1>
            <p>
            If you have any questions, concerns, or feedback about this Privacy Policy, please contact us at:
            <br/>
            AiRander Support Team
            <a href="#">support@airander.com</a>
               
            </p>
            </section>
        </div>
      </div>

        
   
    </div>
  )
}

export default Privacy