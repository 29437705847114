import { useEffect, useRef, useState } from 'react';
import './App.css';
import 'react-toastify/dist/ReactToastify.css';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";



import Landing from './pages/Landing';
import Privacy from './pages/Privacy';


function App() {
  

  return (
    <Router>
      <Routes>
        <Route path="/" element={<Landing />} />
        <Route path="/privacy-policy" element={<Privacy />} />
      </Routes>
   
    </Router>
  );
}

export default App;
